/* eslint-disable unicorn/numeric-separators-style */
import { defineChain } from 'viem';

export const artheraTestnet = defineChain({
  id: 10243,
  name: 'Arthera Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Arthera',
    symbol: 'AA'
  },
  rpcUrls: {
    default: {
      http: ['https://rpc-test.arthera.net']
    }
  },
  blockExplorers: {
    default: { name: 'Arthera Explorer', url: 'https://explorer-test.arthera.net/' }
  }
});
