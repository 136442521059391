import React from 'react';

import { SignIn } from '@clerk/clerk-react';

import ExternalAnchor from '@/components/external-anchor';
import { privacyPolicyUrl, termsOfServiceUrl } from '@/constants/urls';

export default function SignInPage() {
  return (
    <div className='flex h-screen w-full items-center justify-center p-4 lg:px-0'>
      <div className='flex w-full max-w-[350px] flex-col items-center justify-center space-y-6 rounded-lg p-8 shadow-lg'>
        <SignIn />
        <p className='text-center text-sm text-gray-600'>
          By clicking continue, you agree to our{' '}
          <ExternalAnchor
            href={termsOfServiceUrl}
            className='underline underline-offset-4 hover:text-primary'
          >
            Terms of Service
          </ExternalAnchor>{' '}
          and{' '}
          <ExternalAnchor
            href={privacyPolicyUrl}
            className='underline underline-offset-4 hover:text-primary'
          >
            Privacy Policy
          </ExternalAnchor>
          .
        </p>
      </div>
    </div>
  );
}
