import React from 'react';

import type { PropsWithChildren } from 'react';

import { ClerkProvider } from '@clerk/clerk-react';
import { ErrorBoundary } from '@sentry/react';

import { env } from '@/env';

import AnalyticsProvider from './analytics';
import ReactQueryProvider from './react-query';
import WagmiProvider from './wagmi';

type TRootProviderProperties = PropsWithChildren;

export default function RootProvider({ children }: TRootProviderProperties) {
  return (
    <ErrorBoundary>
      <AnalyticsProvider>
        <ClerkProvider
          publishableKey={env.VITE_CLERK_PUBLISHABLE_KEY}
          signInUrl='/sign-in'
          signUpUrl='sign-up'
        >
          <WagmiProvider>
            <ReactQueryProvider>{children}</ReactQueryProvider>
          </WagmiProvider>
        </ClerkProvider>
      </AnalyticsProvider>
    </ErrorBoundary>
  );
}
