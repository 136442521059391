import { compilerEndpoint, documentationLink, fileExtension } from './base';
import { mainnetChains } from './mainnet/chains';
import templates from './templates';
import { testnetChains } from './testnet/chains';

const evmConfig = {
  chains: mainnetChains.sort((a, b) => a.name.localeCompare(b.name)),
  testnetChains: testnetChains.sort((a, b) => a.name.localeCompare(b.name)),
  templates,
  documentationLink,
  compilerEndpoint,
  fileExtension
};

export default evmConfig;
