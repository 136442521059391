import type TChain from '@/types/chain';

import {
  arbitrumSepolia,
  areonNetworkTestnet,
  bscTestnet,
  gnosisChiado,
  lightlinkPegasus,
  lineaTestnet,
  polygonMumbai,
  polygonZkEvmTestnet,
  sepolia,
  zkSyncSepoliaTestnet
} from 'wagmi/chains';

import { artheraTestnet } from './custom/arthera';
import { zkLinkTestnet } from './custom/zk-link';

const testnetChains: TChain[] = [
  {
    name: 'Arbitrum Sepolia',
    logo: 'https://cryptologos.cc/logos/arbitrum-arb-logo.png',
    network: arbitrumSepolia
  },
  {
    name: 'Areon Testnet',
    logo: 'https://s3.coinmarketcap.com/static-gravity/image/a12b852d57c1402c8bbad0f34197471b.png',
    network: areonNetworkTestnet
  },
  {
    name: 'BNB Chain Testnet',
    logo: 'https://cryptologos.cc/logos/bnb-bnb-logo.png',
    network: bscTestnet
  },
  {
    name: 'Ethereum Sepolia',
    logo: 'https://seeklogo.com/images/E/ethereum-logo-EC6CDBA45B-seeklogo.com.png',
    network: sepolia
  },
  {
    name: 'Gnosis Chiado',
    logo: 'https://assets-global.website-files.com/63692bf32544bee8b1836ea6/636a6e764bdb11a70341fab4_owl-forest.png',
    network: gnosisChiado
  },
  {
    name: 'LightLink Pegasus',
    logo: 'https://icodrops.com/wp-content/uploads/2023/04/Yg2XgSeH_400x400-150x150.jpg',
    network: lightlinkPegasus
  },
  {
    name: 'Linea Goerli',
    logo: 'https://s3.coinmarketcap.com/static-gravity/image/203ccaf09aa64c19bc8989db729468a6.jpg',
    network: lineaTestnet
  },
  {
    name: 'Polygon Mumbai',
    logo: 'https://s3.coinmarketcap.com/static-gravity/image/b8db9a2ac5004c1685a39728cdf4e100.png',
    network: polygonMumbai
  },
  {
    name: 'Polygon zkEVM Testnet',
    logo: 'https://assets-global.website-files.com/6364e65656ab107e465325d2/642235057dbc06788f6c45c1_polygon-zkevm-logo.png',
    network: polygonZkEvmTestnet
  },
  {
    name: 'zkSync Sepolia',
    logo: 'https://raw.githubusercontent.com/zkSync-Community-Hub/zksync-community/main/.gitbook/assets/icon_zkSync_Era.png',
    network: zkSyncSepoliaTestnet
  },
  {
    name: 'Arthera Testnet',
    logo: 'https://pbs.twimg.com/profile_images/1777451237316722688/wuR1Qjdr_400x400.jpg',
    network: artheraTestnet
  },
  {
    name: 'zkLink Nova Testnet',
    logo: 'https://pbs.twimg.com/profile_images/1790560996224098305/kI4FEjmn_400x400.png',
    network: zkLinkTestnet
  }
];

const testnetWagmiChains = testnetChains.map((chain) => chain.network);

export { testnetChains, testnetWagmiChains };
