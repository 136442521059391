const config = {
  metadata: {
    title: 'AI DeFi Builder',
    description: 'AI DeFi Builder',
    keywords:
      'react.js, react.js-template, react.js-boilerplate, typescript, shadcn/ui, tailwindcss, vite, swc, eslint, prettier, husky, commitlint, lint-staged'
  },
  server: {
    host: 'localhost',
    port: 3000
  }
};

export default config;
