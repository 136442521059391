import type TChain from '@/types/chain';

import {
  arbitrum,
  areonNetwork,
  bsc,
  coreDao,
  gnosis,
  lightlinkPhoenix,
  linea,
  mainnet,
  polygon,
  polygonZkEvm,
  zkSync
} from 'wagmi/chains';

import { arthera } from './custom/arthera';
import { zkLink } from './custom/zk-link';

const mainnetChains: TChain[] = [
  {
    name: 'Arbitrum',
    logo: 'https://cryptologos.cc/logos/arbitrum-arb-logo.png',
    network: arbitrum
  },
  {
    name: 'Areon',
    logo: 'https://s3.coinmarketcap.com/static-gravity/image/a12b852d57c1402c8bbad0f34197471b.png',
    network: areonNetwork
  },
  {
    name: 'BNB Chain',
    logo: 'https://cryptologos.cc/logos/bnb-bnb-logo.png',
    network: bsc
  },
  {
    name: 'Ethereum',
    logo: 'https://seeklogo.com/images/E/ethereum-logo-EC6CDBA45B-seeklogo.com.png',
    network: mainnet
  },
  {
    name: 'Gnosis',
    logo: 'https://assets-global.website-files.com/63692bf32544bee8b1836ea6/636a6e764bdb11a70341fab4_owl-forest.png',
    network: gnosis
  },
  {
    name: 'LightLink',
    logo: 'https://icodrops.com/wp-content/uploads/2023/04/Yg2XgSeH_400x400-150x150.jpg',
    network: lightlinkPhoenix
  },
  {
    name: 'Linea',
    logo: 'https://s3.coinmarketcap.com/static-gravity/image/203ccaf09aa64c19bc8989db729468a6.jpg',
    network: linea
  },
  {
    name: 'Polygon POS',
    logo: 'https://s3.coinmarketcap.com/static-gravity/image/b8db9a2ac5004c1685a39728cdf4e100.png',
    network: polygon
  },
  {
    name: 'Polygon zkEVM',
    logo: 'https://assets-global.website-files.com/6364e65656ab107e465325d2/642235057dbc06788f6c45c1_polygon-zkevm-logo.png',
    network: polygonZkEvm
  },
  {
    name: 'zkSync',
    logo: 'https://raw.githubusercontent.com/zkSync-Community-Hub/zksync-community/main/.gitbook/assets/icon_zkSync_Era.png',
    network: zkSync
  },
  {
    name: 'Arthera',
    logo: 'https://pbs.twimg.com/profile_images/1777451237316722688/wuR1Qjdr_400x400.jpg',
    network: arthera
  },
  {
    name: 'CoreDAO',
    logo: 'https://pbs.twimg.com/profile_images/1706672237250527232/UTaux5MV_400x400.png',
    network: coreDao
  },
  {
    name: 'zkLink Nova',
    logo: 'https://pbs.twimg.com/profile_images/1790560996224098305/kI4FEjmn_400x400.png',
    network: zkLink
  }
];

const mainnetWagmiChains = mainnetChains.map((chain) => chain.network);

export { mainnetChains, mainnetWagmiChains };
