import React from 'react';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { env } from '@/env';

type TAnalyticsProviderProperties = {
  children: React.ReactNode;
};

export default function AnalyticsProvider({ children }: TAnalyticsProviderProperties) {
  posthog.init(env.VITE_POSTHOG_KEY, {
    api_host: env.VITE_POSTHOG_HOST
  });

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
