import React from 'react';

import posthog from 'posthog-js';

import modularBuilderDemo from '@/assets/images/modular-builder-demo.png';
import { EEvents } from '@/constants/events';

import ExternalAnchor from './external-anchor';
import { Button } from './ui/button';

export default function EarlyAccessBanner() {
  const earlyAccessPage = 'https://defibuilder.com/early-access-deal/';

  return (
    <div className='container flex flex-col items-center text-center lg:flex-row lg:text-left'>
      <div className='space-y-3 lg:flex lg:w-1/2 lg:flex-col lg:justify-center lg:space-y-0'>
        <h2 className='bg-transparent text-4xl leading-tight text-purple-700'>
          GET EARLY ACCESS TO <br /> <b>MODULAR BUILDER</b>
        </h2>
        <ul className='space-y-1 py-3'>
          <li>- Fully Customizable No-Code Deployment</li>
          <li>- Smart Contract and Front-End Setup</li>
          <li>- Admin Dashboard with premium features </li>
          <li>- Early access to premium modules</li>
          <li>- Free Hosting</li>
        </ul>
        <ExternalAnchor href={earlyAccessPage}>
          <Button className='w-full' onClick={() => posthog.capture(EEvents.EARLY_ACCESS_CLICKED)}>
            Discover More
          </Button>
        </ExternalAnchor>
      </div>

      <div className='mt-5 w-full lg:ml-5 lg:mt-0 lg:w-1/2'>
        <img
          src={modularBuilderDemo}
          alt='Modular Builder Demo'
          className='rounded-lg bg-cover lg:mx-0'
          style={{ width: '100%', maxHeight: '100%' }}
        />
      </div>
    </div>
  );
}
