import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { env } from '@/env';

import Layout from './components/layout';
import { Toaster } from './components/ui/toast/toaster';
import HomePage from './pages/home';
import NotFoundPage from './pages/not-found';
import SignInPage from './pages/sign-in';
import SignUpPage from './pages/sign-up';
import RootProvider from './providers/root';

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <BrowserRouter>
      <RootProvider>
        <Layout>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/sign-in' element={<SignInPage />} />
            <Route path='/sign-up' element={<SignUpPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>

          <Toaster />
        </Layout>
      </RootProvider>
    </BrowserRouter>
  );
}

export default App;
